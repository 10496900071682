import Accordions from '../classes/accordions';

// Permet d'initialiser les rubrics avec un offset dynamique ou sans de offset
// Pour un offset dynamique, on doit ajouter des fonctions (ex: onResize, onCloseAlert) pour changer le offset
export default function rubrics() {
  const rubric = new Accordions();
  rubric.offset = 0;
  rubric.scrollToAccordion = false;
  rubric.init();
}
