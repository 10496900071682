import $ from 'jquery';
import { OBSERVER } from '../plugins';
import { isMobile, isVisible } from './helper';
import { clickToScrollToBlock } from './scrollToBlock';

// Permet de créer un calendrier
export function calendar() {
  if (!document.querySelector('.calendar')) {
    // s'il n'y a pas d'events
    return;
  }

  const eventsDatesList = document.querySelector('.calendar').dataset.list;
  const currentDate = document.querySelector('.calendar').dataset.date;

  $.fn.datepicker.dates.fr = {
    days: ['Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi'],
    daysShort: ['Dim', 'Lun', 'Mar', 'Mer', 'Jeu', 'Ven', 'Sam'],
    daysMin: ['D', 'L', 'M', 'M', 'J', 'V', 'S'],
    months: ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre',
      'Décembre'],
    monthsShort: ['Jan', 'Fév', 'Mar', 'Avr', 'Mai', 'Juin', 'Jui', 'Août', 'Sep', 'Oct', 'Nov', 'Déc'],
    today: "Aujourd'hui",
    clear: 'Clear',
    format: 'dd/mm/yyyy',
    titleFormat: 'MM yyyy',
    weekStart: 0,
  };

  const datesEnabled = eventsDatesList.split(',');

  $('.calendar')
    .datepicker({
      language: 'fr',
      maxViewMode: 0,
      format: 'yyyy-mm-dd',
      todayHighlight: true,
      beforeShowDay: (date) => {
        // Rendre seulement les dates de la liste d'événements disponibles
        let returnBoolean;
        const allDates = `${date.getDate()}-${date.getMonth() + 1}-${date.getFullYear()}`;
        if (datesEnabled.indexOf(allDates) !== -1) {
          returnBoolean = true;
        } else {
          returnBoolean = false;
        }
        return returnBoolean;
      },
    })
    .on('changeDate', (e) => {
      // Le timestamp du date picker est en millisecondes, il faut le mettre en secondes
      const theTimestamp = Date.parse(e.date) / 1000;
      $.request('onChangeDate', {
        data: { dateFilter: theTimestamp },
        // update: {'evenementlist::eventslist':'#eventsWrapper'},
        // complete: function (data) { data.then(function(data){ eventsLoaded(data) })},
        complete: (data) => {
          data.then((data2) => {
            // eslint-disable-next-line no-undef
            Barba.Pjax.goTo(`/evenements/1/${data2.date}`);
          });
        },
      });
      // Fermer l'overlay quand on clique
      $('#overlayCalendar .wrapper a.close span.x').trigger('click');
    });

  $('.calendar').datepicker('update', currentDate);
}

// Copier du texte
export function copyTextToClipboard(text) {
  const pos = $(document).scrollTop();

  const textArea = document.createElement('textarea');
  textArea.value = text;
  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();

  try {
    document.execCommand('copy');
  } catch (err) {
    console.error(err);
  }

  document.body.removeChild(textArea);
  $(document).scrollTop(pos);
}

// Ajoute un target blank sur tous les liens externes et PDF
// Empêche une transition si la destination est la page actuelle
export function forceBlankOnExterneAndPdfLinks() {
  let i;
  const anchors = document.querySelectorAll('a[href]');
  for (i = 0; i < anchors.length; i += 1) {
    // eslint-disable-next-line no-script-url
    if (anchors[i].target !== '_blank' && anchors[i].href !== 'javascript:;') {
      if (
        window.location.hostname !== anchors[i].hostname
        || anchors[i].href.match('\\.pdf$')
        || window.location.protocol !== anchors[i].protocol
      ) {
        anchors[i].setAttribute('target', '_blank');
      }
    }
  }
}

// Ajoute les metas pour le contenu og
export function ogContent(data) {
  const ogUrl = data.next.html.match(/<meta.*property="og:url".*content="(.*)".*\/>/i)[1];
  const ogImage = data.next.html.match(/<meta.*property="og:image".*content="(.*)".*\/>/i)[1];
  const ogTitle = data.next.html.match(/<meta.*property="og:title".*content="(.*)".*\/>/i)[1];
  const ogDescription = data.next.html.match(/<meta.*property="og:description".*content="(.*)".*\/>/i)[1];
  document.querySelector('meta[property="og:url"]').setAttribute('content', ogUrl);
  document.querySelector('meta[property="og:image"]').setAttribute('content', ogImage);
  document.querySelector('meta[property="og:title"]').setAttribute('content', ogTitle);
  document.querySelector('meta[property="og:description"]').setAttribute('content', ogDescription);
}

// Permet d'aller au backend
export function backendKeyBind() {
  $('body').on('keydown', (e) => {
    const keycode = e.keyCode ? e.keyCode : e.which;
    if ((e.ctrlKey && keycode === 13) || (e.metaKey && keycode === 13)) {
      window.location = '/administration';
      return false;
    }
    return true;
  });
}

// Permet de mettre les spectacles en style favoris
export function favorite() {
  const putOnFavorite = (e) => {
    const icon = e.target;

    if (icon.classList.contains('favorite')) {
      icon.classList.remove('favorite');
    } else {
      icon.classList.add('favorite');
    }
  };

  OBSERVER.add({
    name: 'favorite',
    events: 'click',
    targets: '.c-show__favorite-icon, .c-show-card__favorite-icon, .c-page-title__favorite-button',
    function: putOnFavorite,
  });
  OBSERVER.on('favorite');
}

// Permet de faire apparaitre un bouton back to top
export const backToTop = (object = {}) => {
  const options = {};

  // Déclaration des propriétés par défaut
  options.container = object.container === undefined ? '[data-js="back-to-top-container"]' : object.container;
  options.backToTopSelector = object.backToTopSelector === undefined ? '[data-js="back-to-top"]' : object.backToTopSelector;
  options.distance = object.distance === undefined ? 500 : object.distance;
  options.showClass = object.showClass === undefined ? 'displayed' : object.showClass;

  // Déclarations des variables
  const container = document.querySelector(options.container);

  // Déclarations des fonctions
  const manageBackToTop = () => {
    if (document.documentElement.scrollTop >= options.distance) {
      container.classList.add(options.showClass);
    } else {
      container.classList.remove(options.showClass);
    }
  };

  // Si le container existe
  if (typeof (container) !== 'undefined' && container != null) {
    // Permet de retourner en haut de la page
    clickToScrollToBlock({ selector: options.backToTopSelector });

    // Appel de la fonction au moins une fois
    manageBackToTop();

    // Ajout de l événement pour masquer ou afficher le contenu
    OBSERVER.add({ name: 'manageBackToTop', events: 'scroll', function: manageBackToTop });
    OBSERVER.on('manageBackToTop');
  }
};

export function redAlert() {
  const alert = document.querySelector('[data-js="alert"]');
  const stickyBlock = document.querySelector('[data-js="sticky-item"]');

  const closeAlert = () => {
    document.documentElement.style.setProperty('--alert-height', '0px');
    document.documentElement.style.setProperty('--alert-gap', '0px');

    if (stickyBlock) {
      stickyBlock.classList.remove('hidden');
    }
  };

  const stickyItem = () => {
    stickyBlock.classList.add('hidden');
  };

  OBSERVER.add({
    name: 'alert',
    events: 'click',
    function: closeAlert,
    targets: '[data-js="close-alert"]',
  });

  if (alert) {
    if (stickyBlock) {
      stickyItem();

      OBSERVER.add({
        name: 'alert',
        events: 'resize',
        function: stickyItem,
        targets: '[data-js="sticky-item"]',
      });
    }
  }

  OBSERVER.on('alert');
}

export function breadcrumbsVisible() {
  const stickyButton = document.querySelector('[data-js="sticky-item"]');
  let breadcrumbs;

  const checkIfItVisible = () => {
    if (isVisible(breadcrumbs)) {
      stickyButton.classList.add('not-visible');
    } else {
      stickyButton.classList.remove('not-visible');
    }
  };

  if (stickyButton) {
    breadcrumbs = document.querySelector('.o-layout__breadcrumbs');
    checkIfItVisible();

    OBSERVER.add({
      name: 'stickyButton',
      events: 'scroll',
      function: checkIfItVisible,
    });

    OBSERVER.on('stickyButton');
  }
}

export function photoCredits() {
  const hoverItems = document.querySelectorAll('.c-photo-credits__hover-item');

  function touch(e) {
    const isCreditsVisible = e.currentTarget.nextElementSibling.classList.contains('visible-credits');
    if (isCreditsVisible) {
      e.currentTarget.nextElementSibling.classList.remove('visible-credits');
    } else {
      e.currentTarget.nextElementSibling.classList.add('visible-credits');
    }
  }

  if (!isMobile()) {
    const hoverIn = (e) => {
      e.currentTarget.nextElementSibling.classList.add('visible-credits');
    };
    const hoverOut = (e) => {
      e.currentTarget.nextElementSibling.classList.remove('visible-credits');
    };

    OBSERVER.add({
      name: 'hover',
      events: 'mouseenter',
      targets: hoverItems,
      function: hoverIn,
    });
    OBSERVER.add({
      name: 'hover',
      events: 'mouseleave',
      targets: hoverItems,
      function: hoverOut,
    });
    OBSERVER.on('hover');
  } else if (isMobile()) {
    OBSERVER.add({
      name: 'touch',
      events: 'click',
      targets: hoverItems,
      function: touch,
    });
    OBSERVER.on('touch');
  }
}

export function changeSelect(select) {
  $('.dropdown-option').on('click', () => {
    $(select).trigger('change');
  });
}
