// Documentation: https://swiperjs.com/swiper-api
// Exemple de slider dans dtg-dentistes
import Swiper from 'swiper/bundle';

// Swiper de la bannière
export default function swiperHomeBanner() {
  // Si il y a plus qu'une slide
  if (document.querySelectorAll('[data-swiper="home-banner-slide"]').length > 1) {
    // Création du slider
    window['home-banner'] = new Swiper('[data-swiper="home-banner"]', {
      loop: true,
      speed: 1000,
      autoplay: {
        delay: 7000,
      },
      slidesPerView: 1,
      effect: 'fade',
      fadeEffect: {
        crossFade: true,
      },
      pagination: {
        el: '[data-swiper="home-banner-pagination"]',
        type: 'bullets',
        clickable: true,
      },
    });
    window['home-banner'].on('slideChange', () => {
      const photoCredits = document.querySelector('.visible-credits');
      if (photoCredits) {
        photoCredits.classList.remove('visible-credits');
      }
    });
  }
}

// Swiper des partenaires
export function swiperBottomPartners() {
  window['bottom-partners'] = new Swiper('[data-swiper="bottom-partners"]', {
    loop: true,
    speed: 500,
    autoplay: {
      delay: 7000,
    },
    slidesPerView: 'auto',
  });
}

// Swiper des spectacles similaires
export function swiperSimilarShows() {
  window['similar-shows'] = new Swiper('[data-swiper="similar-shows"]', {
    loop: true,
    speed: 500,
    autoplay: {
      delay: 7000,
    },
    slidesPerView: 'auto',
  });
}
