import ShareButtons from 'share-buttons/dist/share-buttons';
import { OBSERVER } from '../plugins';
import Overlay from '../vendor/overlay';
import Accordions from '../classes/accordions';

export function overlayShare() {
  const ovShare = new Overlay({
    name: 'share',
    click: {
      buttons: {
        open: '.js-share',
        close: '.close-overlay',
      },
    },
    options: {
      speed: 800,
    },
  });

  ovShare.init();

  // if (typeof __sharethis__ !== 'undefined' && __sharethis__.config) {
  //   __sharethis__.init(__sharethis__.config);
  // }

  // Message de confirmation de copie de l'URL ----------------
  /* L'alert bloque le copy, donc il se fait juste quand on clique sur "OK",
    donc si on veux un alert, il faut mettre un ti timeout de 300ms
    ou ben faire notre prope ti popup sans bouton qui disparait après quelques secondes. */
  function confirmClipboardCopy() {
    // alert('L’adresse URL a bien été copiée dans votre presse-papier.');
  }
  OBSERVER.add({
    name: 'confirmClipboardCopy',
    events: 'click',
    targets: '.js-share-copy',
    function: confirmClipboardCopy,
  });
  OBSERVER.on('confirmClipboardCopy');

  // Gestion de l'ouverture de l'overlay ----------------------
  function openShareOverlay(e) {
    if (e.currentTarget.dataset.rubric) {
      window.history.pushState(null, null, `#${e.currentTarget.dataset.rubric}`);
    }
    document.querySelector('#Share-buttons').setAttribute('data-url', window.location.href);
    ShareButtons.update();
  }
  OBSERVER.add({
    name: 'openShareOverlay',
    events: 'click',
    targets: '.js-share',
    function: openShareOverlay,
  });
  OBSERVER.on('openShareOverlay');

  // Gestion de la fermeture de l'overlay ---------------------
  function closeShareOverlay() {
    window.history.replaceState(null, null, ' ');
    document.querySelector('#Share-buttons').setAttribute('data-url', window.location.href);
    ShareButtons.update();
  }

  OBSERVER.add({
    name: 'closeShareOverlay',
    events: 'click',
    targets: '.overlay-share__background, .overlay-share__close',
    function: closeShareOverlay,
  });
  OBSERVER.on('closeShareOverlay');
}

export function overlayDropdown() {
  new Overlay({
    name: 'dropdown',
    click: {
      buttons: {
        trigger: '[data-js="trigger-overlay-dropdown"]',
        close: '[data-js="close-overlay-dropdown"], a[href]',
        switch: '[data-js="open-overlay-connexion"]',
      },
    },
    animations: {
      selector: '.c-overlay-dropdown__container',
      styles: [{
        property: 'height',
        value: 'dataset',
        easing: 'easeInOutQuart',
      }],
    },
    options: {
      speed: 1000,
      closeOnResize: false,
    },
    events: {
      close: true,
      trigger: true,
    },
  }).init();
}

export function overlayMenu() {
  new Overlay({
    name: 'menu',
    events: {
      close: true,
    },
    create: { close: false },
    click: {
      buttons: {
        toggle: '[data-js="toggle-overlay-menu"]',
        close: '[data-js="close-overlay-menu"], a[href]',
        switch: '[data-js="open-overlay-connexion"]',
      },
    },
    options: {
      speed: 800,
      goToSelector: 'html, body',
      closeOnResize: false,
    },
  }).init();

  const mainLevel = new Accordions({
    name: 'MainLevel',
    containerClass: 'js-accordions-main-level',
    accordionClass: 'js-accordion-main-level',
    toggleButtonClass: 'js-accordion-toggle-main-level',
    contentClass: 'js-accordion-content-main-level',
    openedClass: 'js-accordion-opened-main-level',
  });

  mainLevel.init();

  const secondLevel = new Accordions({
    name: 'SecondLevel',
    containerClass: 'js-accordions-second-level',
    accordionClass: 'js-accordion-second-level',
    toggleButtonClass: 'js-accordion-toggle-second-level',
    contentClass: 'js-accordion-content-second-level',
    openedClass: 'js-accordion-opened-second-level',
  });

  secondLevel.init();

  const onClose = () => {
    mainLevel.closeAll();
    secondLevel.closeAll();
  };

  OBSERVER.add({
    name: 'overlayMenu',
    events: 'onCloseOverlayMenu',
    function: onClose,
  });

  OBSERVER.add({
    name: 'overlayMenu',
    events: 'onCloseAccordionsMainLevel',
    function: onClose,
  });

  OBSERVER.on('overlayMenu');
}
