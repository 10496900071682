import anime from 'animejs/lib/anime.es';
import { scrollToBlock } from '../functions/scrollToBlock';

export default class Accordions {
  set offset(value) {
    this.accordions.offset = value;
  }

  set scrollToAccordion(value) {
    this.accordions.scrollToAccordion = value;
  }

  constructor(object) {
    this.accordions = object;
    this.allIsClosed = true;
    this.lastHeight = 0;
    this.lastIndex = 0;
    this.setDefault();
  }

  setDefault() {
    if (this.accordions === undefined) this.accordions = {};
    if (this.accordions.name === undefined) this.accordions.name = 'Default';
    if (this.accordions.containerClass === undefined) this.accordions.containerClass = 'js-accordions';
    if (this.accordions.accordionClass === undefined) this.accordions.accordionClass = 'js-accordion';
    if (this.accordions.toggleButtonClass === undefined) this.accordions.toggleButtonClass = 'js-accordion-toggle';
    if (this.accordions.contentClass === undefined) this.accordions.contentClass = 'js-accordion-content';
    if (this.accordions.openedClass === undefined) this.accordions.openedClass = 'js-accordion-opened';
    if (this.accordions.easing === undefined) this.accordions.easing = 'easeInOutQuart';
    if (this.accordions.duration === undefined) this.accordions.duration = 700;
    if (this.accordions.offset === undefined) this.accordions.offset = 0;
    if (this.accordions.root === undefined) this.accordions.root = document;
    if (this.accordions.closeAllBeforeOpen === undefined) this.accordions.closeAllBeforeOpen = true;
    if (this.accordions.scrollToAccordion === undefined) this.accordions.scrollToAccordion = true;
  }

  init() {
    const self = this;
    this.addIndexInHTML();
    setTimeout(() => {
      self.hash();
    }, 1000);

    let i;
    this.toggleButtons = [];
    if (this.accordions.toggleButtonClass !== undefined) {
      const sltr = `.${this.accordions.containerClass} .${this.accordions.accordionClass} .${this.accordions.toggleButtonClass}`;
      this.toggleButtons = Array.prototype.slice.call(this.accordions.root.querySelectorAll(sltr));
      const toggleButtonsLength = this.toggleButtons.length;
      for (i = 0; i < toggleButtonsLength; i += 1) {
        this[`boundToggle${i}`] = (e) => this.toggle(e);
        this.toggleButtons[i].addEventListener('click', this[`boundToggle${i}`], false);
      }
    }

    this.boundHash = (e) => this.hash(e);
    window.addEventListener('hashchange', this.boundHash, false);

    this.closeEvent = new CustomEvent(`onCloseAccordions${this.accordions.name}`);
    this.closeAllEvent = new CustomEvent(`onCloseAllAccordions${this.accordions.name}`);
  }

  toggle(e) {
    const element = e.currentTarget.closest(`.${this.accordions.accordionClass}`);
    const currentElementIsOpened = element.classList.contains(this.accordions.openedClass);

    if (!currentElementIsOpened) {
      if (!this.allIsClosed && this.accordions.closeAllBeforeOpen) {
        this.closeAll();
      }
      this.open(e.currentTarget.closest(`.${this.accordions.accordionClass}`));
    } else {
      this.close(e.currentTarget.closest(`.${this.accordions.accordionClass}`));
    }
  }

  hash() {
    let hash;
    let accordion;
    if (window.location.hash) {
      // Si le hash est une rubrique ---------------------
      hash = window.location.hash.substring(1);
      if (document.querySelector(`#accordion-${hash}.js-accordion`)) {
        accordion = this.accordions.root.querySelector(`#accordion-${hash}`);
        if (accordion != null) {
          if (!this.allIsClosed && this.accordions.closeAllBeforeOpen) {
            this.closeAll();
          }
          this.open(accordion);
        }
        setTimeout(() => {
          window.history.replaceState('', document.title, window.location.href.split('#')[0]);
        }, 1000);
      } else if (document.querySelector(`${window.location.hash}`)) {
        scrollToBlock({
          scrollTo: window.location.hash,
          easing: this.accordions.easing,
          duration: this.accordions.duration,
        });
      }
    }
  }

  open(accordion) {
    let i;
    let childrenHeight = 0;
    const containers = this.accordions.root.querySelectorAll(`.${this.accordions.containerClass}`);
    const containersLength = containers.length;

    for (i = 0; i < containersLength; i += 1) {
      containers[i].style.pointerEvents = 'none';
    }

    accordion.classList.add(this.accordions.openedClass);
    const currentContent = this.findAccordionContent(accordion);
    const currentContentChildrenLength = currentContent.children.length;
    for (i = 0; i < currentContentChildrenLength; i += 1) {
      childrenHeight += currentContent.children[i].clientHeight;
    }

    anime({
      targets: currentContent,
      height: childrenHeight,
      easing: this.accordions.easing,
      duration: this.accordions.duration,
      complete: () => {
        for (i = 0; i < containersLength; i += 1) {
          containers[i].style.pointerEvents = '';
        }
        currentContent.style.height = '100%';
      },
    });

    if (this.accordions.scrollToAccordion) {
      const position = this.lastIndex >= accordion.dataset.index ? 0 : -this.lastHeight;
      const positionWithOffset = position + this.accordions.offset;

      scrollToBlock({
        scrollTo: accordion,
        easing: this.accordions.easing,
        duration: this.accordions.duration,
        offset: positionWithOffset,
      });
    }

    this.allIsClosed = false;
    this.lastHeight = childrenHeight;
    this.lastIndex = accordion.dataset.index;
  }

  close(accordion) {
    let i;
    let childrenHeight = 0;

    const containers = this.accordions.root.querySelectorAll(`.${this.accordions.containerClass}`);
    const containersLength = containers.length;

    dispatchEvent(this.closeEvent);

    for (i = 0; i < containersLength; i += 1) {
      containers[i].style.pointerEvents = 'none';
    }

    accordion.classList.remove(this.accordions.openedClass);

    const currentContent = this.findAccordionContent(accordion);

    const currentContentChildrenLength = currentContent.children.length;
    for (i = 0; i < currentContentChildrenLength; i += 1) {
      childrenHeight += currentContent.children[i].clientHeight;
    }

    currentContent.style.height = `${childrenHeight}px`;

    anime({
      targets: currentContent,
      height: '0px',
      easing: this.accordions.easing,
      duration: this.accordions.duration,
      complete: () => {
        for (i = 0; i < containersLength; i += 1) {
          containers[i].style.pointerEvents = '';
        }
      },
    });

    this.allIsClosed = true;
    this.lastHeight = 0;
  }

  closeAll() {
    let i;
    const {
      root, containerClass, accordionClass, openedClass, contentClass,
    } = this.accordions;
    const accordionElements = root.querySelectorAll(`.${containerClass} .${accordionClass}.${openedClass}`);
    const accordionContents = root.querySelectorAll(`.${containerClass} .${accordionClass}.${openedClass} .${contentClass}`);
    const accordionElementsLength = accordionElements.length;

    dispatchEvent(this.closeAllEvent);

    for (i = 0; i < accordionElementsLength; i += 1) {
      accordionElements[i].classList.remove(this.accordions.openedClass);
      let j;
      let childrenHeight = 0;
      const childrenLength = accordionElements[i].querySelector(`.${this.accordions.contentClass}`).children.length;

      for (j = 0; j < childrenLength; j += 1) {
        childrenHeight += accordionElements[i].querySelector(`.${this.accordions.contentClass}`).children[j].clientHeight;
      }

      accordionContents[i].style.height = `${childrenHeight}px`;

      anime({
        targets: accordionContents[i],
        height: '0px',
        easing: this.accordions.easing,
        duration: this.accordions.duration,
      });
    }

    this.allIsClosed = true;
  }

  findAccordionContent(accordion) {
    return accordion.querySelector(`.${this.accordions.contentClass}`);
  }

  addIndexInHTML() {
    let i;
    const sltr = `.${this.accordions.containerClass} .${this.accordions.accordionClass}`;
    const accordions = this.accordions.root.querySelectorAll(sltr);
    const accordionsLength = accordions.length;

    for (i = 0; i < accordionsLength; i += 1) {
      accordions[i].dataset.index = i;
    }
  }

  destroy() {
    let i;
    if (this.accordions.toggleButtonClass !== undefined) {
      const toggleButtonsLength = this.toggleButtons.length;
      for (i = 0; i < toggleButtonsLength; i += 1) {
        this.toggleButtons[i].removeEventListener('click', this[`boundToggle${i}`], false);
      }
    }

    window.removeEventListener('hashchange', this.boundHash, false);
  }
}
