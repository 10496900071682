import barba from '@barba/core';
import LazyLoad from 'vanilla-lazyload';
import { SCROLLFIRE, OBSERVER } from './plugins';
import defaultTransition from './transitions/default-transition';
import {
  backendKeyBind, backToTop, breadcrumbsVisible, favorite, ogContent, photoCredits, redAlert, changeSelect,
} from './functions/functions';
import rubrics from './functions/rubrics';
import dynamicPackage from './functions/dynamic';
import {
  resetDropzone, formsPackage, tailSelect, inputsAndTextareaLabel, clearInput, uncheckCheckboxes,
} from './functions/form';
import { overlayShare, overlayDropdown, overlayMenu } from './functions/overlays';
import Overlay from './vendor/overlay';
import {
  formAccount, formConnexion, formContact, formExample, formMyAccount, formPassword, formSuccess,
  formVolonteer, formNewsletterPage, formNewsletterFooter,
} from './functions/validation';
import masksPackage from './functions/masks';
import { hundredVH, removeHoverOnMobile, sizeTitle } from './functions/helper';
import swiperHomeBanner, { swiperBottomPartners, swiperSimilarShows } from './functions/sliders';
import { scrollToBlock, clickToScrollToBlock } from './functions/scrollToBlock';
import {
  googleMap, destroyMap, overlayLocation, overlayLocations, goBackMap, subMenu, drawers,
} from './functions/map/map';

window.formSuccess = formSuccess;
window.resetDropzone = resetDropzone;
window.refreshAfterAjax = refreshAfterAjax;
window.scrollToBlock = scrollToBlock;


export function refreshAfterAjax() {
  window['lazyload'] = new LazyLoad();
  SCROLLFIRE.init();
  favorite();
}

const initBarba = () => {
  barba.hooks.beforeEnter(({ current }) => {
    if (current.container != null) {
      current.container.remove();
    }
    overlayShare();
    overlayDropdown();
    overlayMenu();
    backendKeyBind();
    SCROLLFIRE.init();
    removeHoverOnMobile();
    inputsAndTextareaLabel();
    clearInput();
    swiperBottomPartners();
    backToTop();
    clickToScrollToBlock({ selector: '[data-js="scroll-to-top"]' });
    redAlert();
    photoCredits();
    formNewsletterFooter();
    hundredVH();
    sizeTitle({
      selectors: '.c-page-title__title',
      caract01: 40,
      caract02: 50,
      caract03: 70,
    });
  });

  barba.hooks.enter((data) => {
    ogContent(data);
  });

  barba.hooks.afterLeave(() => {
    OBSERVER.allOff();
    OBSERVER.removeAll();
    Overlay.destroyAll();
    SCROLLFIRE.destroy();
  });

  barba.init({
    prefetchIgnore: true,
    cacheIgnore: true,
    debug: true,
    timeout: 5000,
    prevent: ({ el }) => el.classList && el.classList.contains('js-barba-prevent'),

    transitions: [defaultTransition()],

    views: [
      {
        namespace: 'standardView',
        beforeEnter() {
          rubrics();
          formsPackage();
          masksPackage();
          dynamicPackage();
          swiperSimilarShows();
          favorite();
          formExample();
          window.lazyload = new LazyLoad();
        },
        afterLeave() {
          window.lazyload.destroy();
        },
      },
      {
        namespace: 'maintenanceView',
        beforeEnter() {
          dynamicPackage();
          window.lazyload = new LazyLoad();
        },
        afterLeave() {
          window.lazyload.destroy();
        },
      },
      {
        namespace: '404View',
        beforeEnter() {
          window.lazyload = new LazyLoad();
        },
        afterLeave() {
          window.lazyload.destroy();
        },
      },
      {
        namespace: '500View',
        beforeEnter() {
          window.lazyload = new LazyLoad();
        },
        afterLeave() {
          window.lazyload.destroy();
        },
      },
      {
        namespace: 'homeView',
        beforeEnter() {
          sizeTitle({
            selectors: '.c-home-banner__title',
            caract01: 40,
            caract02: 60,
            caract03: 80,
          });
          swiperHomeBanner();
          favorite();
          clickToScrollToBlock({ selector: '[data-js="scroll-to-shows"]', scrollTo: '[data-js="shows"]' });
          window.lazyload = new LazyLoad();
        },
        afterLeave() {
          window.lazyload.destroy();
        },
      },
      {
        namespace: 'showsListView',
        beforeEnter() {
          tailSelect();
          uncheckCheckboxes({ parent: '[data-js="shows-filters-field"]' });
          favorite();
          breadcrumbsVisible();
          changeSelect('#shows-select');
          window.lazyload = new LazyLoad();
        },
        afterLeave() {
          window.lazyload.destroy();
        },
      },
      {
        namespace: 'showDetailView',
        beforeEnter() {
          rubrics();
          dynamicPackage();
          favorite();
          swiperSimilarShows();
          breadcrumbsVisible();
          window.lazyload = new LazyLoad();
        },
        afterLeave() {
          window.lazyload.destroy();
        },
      },
      {
        namespace: 'newsListView',
        beforeEnter() {
          tailSelect();
          changeSelect('#news-select');
          window.lazyload = new LazyLoad();
        },
        afterLeave() {
          window.lazyload.destroy();
        },
      },
      {
        namespace: 'newsDetailView',
        beforeEnter() {
          dynamicPackage();
          clickToScrollToBlock({ selector: '[data-js="scroll-to-content"]', scrollTo: '[data-js="content"]' });
          window.lazyload = new LazyLoad();
        },
        afterLeave() {
          window.lazyload.destroy();
        },
      },
      {
        namespace: 'contactUsView',
        beforeEnter() {
          formsPackage();
          masksPackage();
          rubrics();
          formContact();
          window.lazyload = new LazyLoad();
        },
        afterLeave() {
          window.lazyload.destroy();
        },
      },
      {
        namespace: 'searchResultsView',
        beforeEnter() {
          tailSelect();
          window.lazyload = new LazyLoad();
        },
        afterLeave() {
          window.lazyload.destroy();
        },
      },
      {
        namespace: 'interactiveMapView',
        beforeEnter() {
          overlayLocation();
          overlayLocations();
          goBackMap();
          subMenu();
          drawers();
          googleMap();
          window.lazyload = new LazyLoad();
        },
        afterLeave() {
          destroyMap();
          window.lazyload.destroy();
        },
      },
      {
        namespace: 'becomeAVolunteerView',
        beforeEnter() {
          formsPackage();
          masksPackage();
          formVolonteer();
          window.lazyload = new LazyLoad();
        },
        afterLeave() {
          window.lazyload.destroy();
        },
      },
      {
        namespace: 'createAccountView',
        beforeEnter() {
          formsPackage();
          masksPackage();
          formAccount();
          window.lazyload = new LazyLoad();
        },
        afterLeave() {
          window.lazyload.destroy();
        },
      },
      {
        namespace: 'createAccountConfirmationView',
        beforeEnter() {
          formsPackage();
          masksPackage();
          window.lazyload = new LazyLoad();
        },
        afterLeave() {
          window.lazyload.destroy();
        },
      },
      {
        namespace: 'myAccountView',
        beforeEnter() {
          formsPackage();
          masksPackage();
          formMyAccount();
          window.lazyload = new LazyLoad();
        },
        afterLeave() {
          window.lazyload.destroy();
        },
      },
      {
        namespace: 'myShowsView',
        beforeEnter() {
          window.lazyload = new LazyLoad();
        },
        afterLeave() {
          window.lazyload.destroy();
        },
      },
      {
        namespace: 'newsletterView',
        beforeEnter() {
          formsPackage();
          masksPackage();
          formNewsletterPage();
          window.lazyload = new LazyLoad();
        },
        afterLeave() {
          window.lazyload.destroy();
        },
      },
      {
        namespace: 'loginView',
        beforeEnter() {
          formsPackage();
          masksPackage();
          formConnexion();
          window.lazyload = new LazyLoad();
        },
        afterLeave() {
          window.lazyload.destroy();
        },
      },
      {
        namespace: 'passwordView',
        beforeEnter() {
          formsPackage();
          masksPackage();
          formPassword();
          window.lazyload = new LazyLoad();
        },
        afterLeave() {
          window.lazyload.destroy();
        },
      },
    ],
  });
};

document.addEventListener('DOMContentLoaded', () => {
  initBarba();
});
